'use client';
import { useEffect } from 'react';

import { datadogRum } from '@datadog/browser-rum';

let datadogLoaded = false;

export const DatadogInit = () => {
  useEffect(() => {
    if (datadogLoaded) return;

    datadogRum.init({
      applicationId: `${process.env.NEXT_PUBLIC_DATADOG_APPLICATION_ID}`,
      clientToken: `${process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN}`,
      site: 'datadoghq.com',
      service: 'website-frontend',
      env: process.env.NODE_ENV,
      sessionSampleRate: 10,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'mask-user-input',
      enableExperimentalFeatures: process.env.NODE_ENV === 'development' ? ['debug'] : [],
    });

    datadogLoaded = true;
  }, []);

  return null;
};
